
import { defineComponent } from "vue"
import { useRouter } from "vue-router"
import { random } from "lodash"
import useUserStore from "@/store/modules/user"
const COLORS = ["#67C23A", "#E6A23C", "#F56C6C", "#409EFF"]
const date = new Date()
export default defineComponent({
  name: "WorkPlace",
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const fastActionClick = ({ path = "/" }) => {
      router.push(path)
    }
    function onPreview(item: { target: string | URL | undefined }) {
      window.open(item.target)
    }
    const dataList = [
      {
        key: "1",
        projectName: "Admin Work Pro",
        tags: ["vue3", "vite", "naive-ui", "typescript"],
        isEmpower: true,
        status: "持续更新",
        target: "http://p.vueadminwork.com",
      },
      {
        key: "2",
        projectName: "Arco Work",
        tags: ["vue3", "vite", "arco-design", "typescript"],
        isEmpower: false,
        status: "持续更新",
        target: "http://arco.vueadminwork.com",
      },
      {
        key: "3",
        projectName: "Admin Work",
        tags: ["vue3", "vite", "naive-ui", "typescript"],
        isEmpower: false,
        status: "持续更新",
        target: "http://aw.vueadminwork.com",
      },
      {
        key: "4",
        projectName: "Admin Work X",
        tags: ["vue3", "vite/webpack", "element-plus", "typescript"],
        isEmpower: false,
        status: "持续更新",
        target: "http://x.vueadminwork.com",
      },
      {
        key: "5",
        projectName: "Admin Work A",
        tags: ["vue3", "vite", "ant-design", "typescript"],
        isEmpower: false,
        status: "持续更新",
        target: "http://a.vueadminwork.com",
      },
      {
        key: "6",
        projectName: "Admin Work Basic",
        tags: ["vue2", "webpack", "element-ui", "javascript"],
        isEmpower: false,
        status: "停止维护",
        target: "http://qingqingxuan.gitee.io/arco-work",
      },
    ]
    return {
      dataList,
      avatar: userStore.avatar,
      currentDate:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      fastActions: [
        {
          title: "首页",
          icon: "icon-dashboard-fill",
          path: "/",
          color: COLORS[random(0, COLORS.length)],
        },
        {
          title: "系统管理",
          path: "/system/department",
          icon: "icon-setting-fill",
          color: COLORS[random(0, COLORS.length)],
        },
        {
          title: "列表",
          path: "/list/table-custom",
          icon: "icon-detail-fill",
          color: COLORS[random(0, COLORS.length)],
        },
        {
          title: "表单",
          path: "/form/base-form-view",
          icon: "icon-file-text-fill",
          color: COLORS[random(0, COLORS.length)],
        },
        {
          title: "多级菜单",
          path: "/next/menu2/menu-2-1/menu-2-1-1",
          icon: "icon-golden-fill",
          color: COLORS[random(0, COLORS.length)],
        },
        {
          title: "更多功能",
          path: "/other/qrcode",
          icon: "icon-appstore-fill",
          color: COLORS[random(0, COLORS.length)],
        },
      ],
      fastActionClick,
      onPreview,
    }
  },
})
